import React, { FC, useContext, useState } from 'react';
import {
  Flex,
  Hide,
  InOut,
  ProgressBar,
  ScreenDevices,
  SpanBody1,
  SpanBody2,
  SpanCaption1,
  SpanH5,
  Svg,
  Switch,
  ThemeType,
} from '@linkeo.com/ui-lib-react';
import styled, { ThemeContext } from 'styled-components';
import { GroupedAnswer } from '../../interfaces/api.types';
import { FormattedMessage, useIntl } from 'react-intl';

interface ProgressProps {
  progressPercent: number;
  questionAnswers: (GroupedAnswer | undefined)[];
}

const Aside = styled.aside`

`;
const LabelCheck: FC<{ title: string }> = props => {
  const theme = useContext<ThemeType>(ThemeContext);
  const { title } = props;
  return <Flex alignItems={['center']} wraps={['wrap']}>
    <Svg width={24} height={24} fill={theme.colors.primary} icon={'check'}
         style={{ marginRight: '5px' }} />
    <SpanCaption1 style={{ color: '#808080' }}>{title} :&nbsp;</SpanCaption1>

  </Flex>;
};
export const Progress: FC<ProgressProps> = props => {
  const { progressPercent, questionAnswers } = props;
  const [getShowMobile, setShowMobile] = useState<ScreenDevices[]>(['xs', 'small', 'tablet']);
  const intl = useIntl();
  return <Aside>
    <h1 style={{ margin: '0' }}>
      <SpanH5>{intl.formatMessage({ id: 'appTitle', defaultMessage: 'Votre estimation en ligne' })}</SpanH5>
    </h1>
    <Hide devices={['desktop']}>
      <Switch
        onLabel={intl.formatMessage({ id: 'yes', defaultMessage: 'Oui' })}
        offLabel={intl.formatMessage({ id: 'no', defaultMessage: 'Non' })}
        label={intl.formatMessage({ id: 'mobileViewSwitchLabel', defaultMessage: 'Afficher le résumé' })}
        value={!getShowMobile.length}
        onChange={(v) => setShowMobile(v ? [] : ['xs', 'small', 'tablet'])}
      />
    </Hide>
    <div style={{ margin: '30px 0' }}>
      <ProgressBar value={progressPercent} />
    </div>
    <div>
      {(progressPercent <= 5) && <div style={{ marginBottom: '20px' }}>
        <SpanBody2>
          {intl.formatMessage({ id: 'welcome', defaultMessage: 'Bienvenue' })},
        </SpanBody2>
        <br />
        <br />
        <SpanBody1>
          <FormattedMessage id={'appIntro'} defaultMessage={'Répondez à ces quelques questions pour obtenir une estimation.{br}Nous pourrons vous fournir un devis plus détaillé à l’issue de cette première estimation.'} values={{br: < br/>}} />
        </SpanBody1>
      </div>}
      <Hide devices={getShowMobile}>
        {
          questionAnswers.map((pa, index) => <InOut
            show={true} startTriggering key={index}>
            {pa && pa.responsesTexte && questionAnswers[index] && index === 0 ?
              <SpanBody2>{pa?.activityTitle}</SpanBody2> : null
            }
            {pa && pa.responsesTexte && questionAnswers[index - 1] && questionAnswers[index - 1]?.activityTitle !== pa?.activityTitle ?
              <SpanBody2>{pa?.activityTitle}</SpanBody2> : null
            }
            {pa && pa.responsesTexte && <LabelCheck title={pa.question.label} />}
            {pa && pa.responsesTexte && pa.responsesTexte.map(rt => <div
              key={rt}
              style={{ paddingLeft: '30px', marginBottom: '5px' }}>
              <SpanBody1>{rt}</SpanBody1>
            </div>)}
            {pa && pa.overallQuantityLabel && pa.overallQuantityResponse && <>
              <LabelCheck title={pa.overallQuantityLabel} />
              <div
                style={{ paddingLeft: '30px', marginBottom: '5px' }}>
                <SpanBody1>{pa.overallQuantityResponse} {pa.overallQuantityUnit}</SpanBody1>
              </div>
            </>}
          </InOut>)
        }
      </Hide>
    </div>
  </Aside>;
};
