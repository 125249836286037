import React from 'react';
import frFR from './lang/fr-FR.json';
import enAU from './lang/en-AU.json';
import enCA from './lang/en-CA.json';
import enUS from './lang/en-US.json';
import frCA from './lang/fr-CA.json';
import esUS from './lang/es-US.json';
import root from 'react-shadow/styled-components';

import { IntlProvider, Locale } from './providers/intl.provider';
import { Theme } from './config/theme';
import { ThemeProvider } from '@linkeo.com/ui-lib-react';
import { HomePage } from './pages/home/home.page';
import { ApiProvider } from './providers/api-provider';
import { ConfirmProvider } from './providers/confirm-provider';
import GlobalStyles from './globalStyles';
import { api } from './config/config';
import { Error404 } from './pages/error/error-404';

const getMessage = (locale: Locale) => {
  switch (locale) {
    default:
    case 'fr-FR':
      return frFR;
    case 'en-AU':
      return enAU;
    case 'en-CA':
      return enCA;
    case 'en-US':
      return enUS;
    case 'fr-CA':
      return frCA;
    case 'es-US':
      return esUS;
  }
};

interface AppProps {
  codeBouton?: string | null;
  formId?: string | null;
  locale: Locale;
}

function App({ codeBouton, formId, locale }: AppProps) {

  return (
    <root.div>
      <GlobalStyles />
      <ThemeProvider theme={Theme}>
        <IntlProvider locale={locale} messages={getMessage(locale)}>
          {codeBouton && formId ? <ConfirmProvider>
            <ApiProvider api={api} codeBouton={codeBouton}>
              <HomePage formId={formId} />
            </ApiProvider>
          </ConfirmProvider> : <Error404 missingArg={true}/>}
        </IntlProvider>
      </ThemeProvider>
    </root.div>
  );
}

export default App;
