import React, { FC, useContext } from 'react';
import { Flex, getTypo, Svg, ThemeType } from '@linkeo.com/ui-lib-react';
import styled, { ThemeContext } from 'styled-components';
import { FormattedMessage } from 'react-intl';

const Background = styled.div`
  height: 100vh;
  position: relative;
  background: #f5f5f5;
`;
const H1 = styled.h1`
  ${getTypo('h1')};
  color: black;
  margin: 10px 0;
`;
const Text = styled.p`
  ${getTypo('h6')};
  color: ${({ theme }) => theme.colors.grey40};
  margin: 30px 0;
`;
export const PageError: FC = () => {
  const theme = useContext<ThemeType>(ThemeContext);
  return (
    <Background>
      <Flex justifyContent={['center']} alignItems={['center']} style={{ height: '100%' }}>
        <div style={{ textAlign: 'center' }}>
          <Svg icon={'cross-circle'} width={36} height={36} fill={theme.colors.grey60} />
          <H1>500</H1>
          <Text>
            <FormattedMessage id={'serverError'}
                              defaultMessage={'Une erreur s’est produite alors que{br}le serveur tentait de traiter la{br}demande.'}
                              values={{ br: <br /> }} />
          </Text>
        </div>
      </Flex>
    </Background>
  );
};
