import React, { createContext, FC } from 'react';
import { createIntl, createIntlCache, IntlShape, RawIntlProvider } from 'react-intl';

const cache = createIntlCache();
export const Locales = ['fr-FR', 'fr-CA', 'en-US','es-US', 'en-AU', 'en-CA'] as const;
export type Locale = typeof Locales[number];

interface IntlProviderProps {
  locale: Locale,
  messages: Record<string, string>
}

interface IntlContext extends IntlProviderProps {
  intl: IntlShape;
}

const renderContent = (intl: IntlShape, children: React.ReactNode) => (
  <RawIntlProvider value={intl}>{children}</RawIntlProvider>
);

const LocalContext = createContext<IntlContext>(
  {
    messages: {},
    locale: 'fr-FR',
    intl: createIntl({
      locale: 'fr-FR',
      messages: {},
    }, cache),
  },
);

export const IntlProvider: FC<IntlProviderProps> = props => {
  const { children, locale, messages } = props;
  const intl = createIntl({
    locale,
    messages,
  }, cache);

  const content = renderContent(intl, children);
  return <LocalContext.Provider value={{ messages, locale, intl }}>{content}</LocalContext.Provider>;
};
