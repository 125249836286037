export const checkImageExists = (uri: string): Promise<boolean> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = uri;
    if (img.complete) {
      resolve(true);
    } else {
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
    }
  });
}