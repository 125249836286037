import React, { FC } from 'react';
import {
  Checkbox,
  Col,
  Input,
  InputNumber,
  LabelWrapper,
  Row,
  SelectOption,
  SimpleSelect,
  Textarea,
} from '@linkeo.com/ui-lib-react';
import { QuestionChoices, QuestionNumber } from '../../interfaces/question.types';
import { ChoiceSelection, GroupedAnswer } from '../../interfaces/api.types';
import { Choice } from '../../interfaces/choice.types';
import { FindByAndRemoveAllItemInArray } from '../../utils/deep-object.utils';

interface SimpleQuestionProps {
    questionAnswer: GroupedAnswer;
    onChange: (val: GroupedAnswer) => void;
}

export const SimpleQuestion: FC<SimpleQuestionProps> = props => {
    const {questionAnswer, onChange} = props;
    const {question, inputData, isInvalid} = questionAnswer;

  const checkboxChange = (evt: boolean, choice: Choice) => {
    const inputValues: ChoiceSelection[] = evt ? [...inputData, { choice,id: choice.id }] :
      FindByAndRemoveAllItemInArray((inputData as ChoiceSelection[]) || [], 'id', choice);
    onChange({
      ...questionAnswer,
      inputData: inputValues,
      responsesTexte: inputValues.map(iv => iv.choice.label),
    });
  };
  switch (question.type) {
    default:
    case 'SHORT_TEXT':
      return <Input
        value={(inputData as string)}
        onChange={(v) => onChange({
          ...questionAnswer,
          inputData: v,
          responsesTexte: [v],
        })}
        type={question.format ? question.format === 'TEL' ? 'tel' : question.format === 'EMAIL' ? 'email' : 'text' : 'text'}
        label={question.label}
        caption={question.description || ''}
        hasError={isInvalid}
        required={question.mandatory}
      />;
    case 'QUANTITY':
      return <InputNumber
        label={question.label + ((question as QuestionNumber).unit ? ` (${(question as QuestionNumber).unit})` : '')}
        caption={question.description || ''}
        hasError={isInvalid}
        required={question.mandatory}
        value={(inputData as number)}
        min={1}
        step='any'
        onChange={(v) => onChange({
          ...questionAnswer,
          inputData: v,
          responsesTexte: [(v || 0).toString(10) + ((question as QuestionNumber).unit ? ` ${(question as QuestionNumber).unit}` : '')],
        })}
      />;
    case 'LONG_TEXT':
        return <Textarea
            label={question.label}
            value={(inputData as string)}
            hasError={isInvalid}
            onChange={(v) => onChange({
                ...questionAnswer,
                inputData: v,
                responsesTexte: [v],
            })}
            caption={question.description || ''}/>;
    case 'MULTIPLE_CHOICE':
      return <LabelWrapper
          label={question.label}
          caption={question.description || ''}
          hasError={isInvalid}
          required={question.mandatory}>
        <Row wraps={['wrap']}>
          {(question as QuestionChoices).choices.map(c => <Col key={c.id}>
            <Checkbox
              value={(inputData as Choice[])?.findIndex(c2 => c2.id === c.id) !== -1}
              onChange={(evt) => checkboxChange(evt, c)}
            >
              {c.label}
            </Checkbox></Col>)}
        </Row>
      </LabelWrapper>;
    case 'SINGLE_CHOICE':
      return <SimpleSelect<Choice>
          options={(question as QuestionChoices).choices.map(c => ({
            label: c.label,
            value: c,
            id: c.id
        } as SelectOption<Choice>))}
          required={question.mandatory}
          label={question.label}
          caption={question.description || ''}
          selected={inputData}
          hasError={isInvalid}
          onSelect={v => onChange({
          ...questionAnswer,
          inputData: v,
          responsesTexte: [v.label],
        })}
      />;
  }
};
