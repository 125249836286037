import {Activity, BaseActivity} from './activity.types';
import {Origin} from './api.types';

export const AIDES_ET_PRIMES = 'AIDES_ET_PRIMES';
export interface BaseCategory {
    name: string;
    type: Origin;
    activities: BaseActivity[];
    formType?: typeof AIDES_ET_PRIMES|null;
}

export interface Category extends BaseCategory {
    id: string;
    activities: Activity[];
}
