import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import { Locale } from './providers/intl.provider';
import App from './App';
// polyfills
import 'web-animations-js/web-animations.min';

const script = document.currentScript;
const root = document.createElement('div');
if (script && script.parentElement !== document.head) {
  script.after(root)
} else {
  document.body.appendChild(root)
}

let codeBouton;
let formId;
let locale: Locale = 'fr-FR';

const getParams = function() {
  if (script && 'src' in script) {
    const searchParams = new URL(script.src).searchParams;
    codeBouton = searchParams.get('codebouton');
    formId = searchParams.get('formid');
    const lang =  searchParams.get('locale')
    if (lang) {
      locale = lang as Locale
    }
    if (!codeBouton && !formId && !lang) {
      const urlParams = new URLSearchParams(window.location.search);
      codeBouton = urlParams.get('codebouton');
      formId = urlParams.get('formid');
      const lang = urlParams.get('locale')
      if (lang) {
        locale = lang as Locale
      }
    }
  }
}
getParams();

ReactDOM.render(
    <React.StrictMode>
        <App codeBouton={codeBouton} formId={formId} locale={locale}/>
    </React.StrictMode>,
    root,
);
