import React, { FC, useState } from 'react';
import { ProgressScope } from '../../interfaces/api.types';
import { Col, InOut, Row, SpanBody2, SpanCaption1 } from '@linkeo.com/ui-lib-react';
import styled from 'styled-components';
import { FormFooter } from './form-footer';
import { Activity } from '../../interfaces/activity.types';
import { FindByAndRemoveAllItemInArray } from '../../utils/deep-object.utils';
import { SelectableImageCard } from '../ui/selectable-image-card';
import { QuestionChoices } from '../../interfaces/question.types';
import { PaddingForCard } from '../ui/padding-for-card';
import { useIntl } from 'react-intl';
import { AIDES_ET_PRIMES, Category } from '../../interfaces/category.types';

interface ActivitiesSelectionProps {
  context: ProgressScope;
  noTargeting: boolean;
  onActivitiesSelected: (activities: ProgressScope[]) => void;
  onPrevious: () => void;
}

const CategoryContainer = styled.div`
  text-align: center;
  margin-bottom: 40px;
`;

export const ActivitiesSelection: FC<ActivitiesSelectionProps> = props => {
  const { onActivitiesSelected, context, onPrevious, noTargeting } = props;
  const [getSelection, setSelection] = useState<ProgressScope[]>([]);
  const intl = useIntl();

  const emitSelection = () => {
    onActivitiesSelected(getSelection);
    setSelection([]);
  };

  const toggleSelection = (selection: Activity, cat: Category) => {
    const existId = getSelection.find(s => s.activityId === selection.id);
    if (!existId) {
      if (cat.formType === AIDES_ET_PRIMES) {
        setSelection([{
          title: selection.name,
          scope: 'ACTIVITY',
          activityId: selection.id,
          isActivityPresentation: true,
          activityImage: selection.picture?.absoluteUrl
        },
          ...selection.questions.map(q =>
            ({
              activityId: selection.id,
              scope: 'ACTIVITY',
              title: intl.formatMessage({
                id: 'activityPageTitle',
                defaultMessage: 'Précisez votre besoin pour {activityName}',
              }, { activityName: selection.name }),
              subtitle: q.label,
              ineligibilityCustomMessage: selection.ineligibilityCustomMessage,
              activityGroup: {
                question: q,
                activityTitle: selection.name,
                booleanFirst: q.type === 'SINGLE_CHOICE' || q.type === 'MULTIPLE_CHOICE' ? !(q as QuestionChoices).booleanFirst : false,
              },
            } as ProgressScope)),

        ]);
      } else {
        setSelection([
          ...getSelection,
          {
            title: selection.name,
            scope: 'ACTIVITY',
            activityId: selection.id,
            isActivityPresentation: true,
            activityImage: selection.picture?.absoluteUrl,
            ineligibilityCustomMessage: selection.ineligibilityCustomMessage
          },
          ...selection.questions.map(q =>
            ({
              activityId: selection.id,
              scope: 'ACTIVITY',
              title: intl.formatMessage({
                id: 'activityPageTitle',
                defaultMessage: 'Précisez votre besoin pour {activityName}',
              }, { activityName: selection.name }),
              subtitle: q.label,
              ineligibilityCustomMessage: selection.ineligibilityCustomMessage,
              activityGroup: {
                question: q,
                activityTitle: selection.name,
                booleanFirst: q.type === 'SINGLE_CHOICE' || q.type === 'MULTIPLE_CHOICE' ? !(q as QuestionChoices).booleanFirst : false,
              },
            } as ProgressScope)),

        ]);
      }
      return;
    }
    setSelection(FindByAndRemoveAllItemInArray(getSelection, 'activityId', selection.id));
  };

  return <InOut show={true} startTriggering>
    <PaddingForCard>
      {context.categories ?
        context.categories.filter((c) => c.activities.some(a => a.questions.length !== 0))
          .map((cat, catIndex) => <CategoryContainer key={catIndex}>
            <div style={{ marginBottom: '20px' }}>
              <h5 style={{ margin: '0' }}>
                <SpanBody2>{cat.name}</SpanBody2>
              </h5>
              {cat.activities.length > 1 && cat.formType !== AIDES_ET_PRIMES && <p style={{ margin: '0' }}>
                <SpanCaption1>
                  {intl.formatMessage({ id: 'moreChoice', defaultMessage: 'Plusieurs choix possibles' })}
                </SpanCaption1>
              </p>
              }
            </div>
            <Row wraps={['wrap']}>
              {cat.activities.filter(a => a.questions.length !== 0).map((act, actIndex) => <Col
                key={actIndex}
                columns={[12, 6, 6, 6]} style={{ marginBottom: '16px' }}>
                <SelectableImageCard
                  active={getSelection.some((s) => s.activityId === act.id)}
                  onSelect={() => toggleSelection(act, cat)}
                  name={act.name}
                  description={act.description || ''}
                  imgUrl={act.picture?.absoluteUrl || ''} />
              </Col>)}
            </Row>
          </CategoryContainer>)
        : null}
    </PaddingForCard>
    <FormFooter
      onCancel={noTargeting ? undefined : onPrevious}
      title={intl.formatMessage({ id: 'validate', defaultMessage: 'Valider' })}
      onClick={emitSelection}
      disabled={!getSelection.length} />
  </InOut>;
};
