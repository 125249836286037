import React, { FC } from 'react';
import { EstimateRecapCard } from './estimate-recap-card';
import { useIntl } from 'react-intl';
import { getTypo, SpanBody1 } from '@linkeo.com/ui-lib-react';
import styled, { useTheme } from 'styled-components';

const TextWrapper = styled.div`
  ${getTypo('body1')};
  color: ${({ theme }) => theme.colors.grey40}
`;

export const IneligibleEstimation: FC<{ ineligibleMessage?: string }> = ({ ineligibleMessage }) => {
  const intl = useIntl();
  const theme = useTheme();

  return <EstimateRecapCard
    result={intl.formatMessage({ id: 'ineligibleMessage', defaultMessage: 'NON ÉLIGIBLE' })}
    topSubtitle={intl.formatMessage({
      id: 'ineligibleEstimationSubtitle',
      defaultMessage: 'Malheureusement nous estimons que vous êtes :',
    })}>
    <TextWrapper>
      <SpanBody1 style={{ color: theme.colors.grey40 }}>
        {ineligibleMessage && <div dangerouslySetInnerHTML={{ __html: ineligibleMessage }} />}
      </SpanBody1>
    </TextWrapper>
  </EstimateRecapCard>;
};