import {GroupedAnswer} from '../../interfaces/api.types';
import {UpdateItemInArray} from '../../utils/deep-object.utils';
import {QuestionChoices} from '../../interfaces/question.types';
import { SelectOption } from '@linkeo.com/ui-lib-react';
import { Choice } from '../../interfaces/choice.types';

export const GroupSetInvalid = (group: GroupedAnswer[], questionAnswer: GroupedAnswer, index: number): GroupedAnswer[] => {
  return UpdateItemInArray(group, index, {
    ...questionAnswer,
    isInvalid: true
  })
}
export const GroupSetAllValid = (group: GroupedAnswer[]): GroupedAnswer[] => {
  return group.map(g => ({...g, isInvalid: false}))
}
type GroupAnswerRefId = {item: GroupedAnswer,index:number};
export const ActivitiesGetInvalids = (activity: GroupedAnswer): boolean => {
  switch (activity.question.type) {
    default:
    case 'LONG_TEXT':
    case 'QUANTITY':
    case 'SHORT_TEXT':
      if (!activity.question.mandatory) {
        return false;
      }
      return !activity.inputData;
    case 'MULTIPLE_CHOICE':
    case 'SINGLE_CHOICE':
      if (activity.question.mandatory) {
        if ((activity.question as QuestionChoices).booleanFirst && activity.booleanFirst === false) {
          return false;
        }
        if (!activity.inputData || !activity.inputData.length) {
          return true
        }
      }
      return false;
  }
}
export const GroupGetInvalids = (group: GroupedAnswer[]): GroupAnswerRefId[] => {
  const invalids: GroupAnswerRefId[] = [];
  group.forEach((item, index) => {
    switch (item.question.type) {
      default:
      case 'LONG_TEXT':
      case 'QUANTITY':
      case 'SHORT_TEXT':
        if (!item.question.mandatory) {
          return;
        }
        if(!item.inputData){
          invalids.push({
            index,
            item
          })
        }
        break;
      case 'MULTIPLE_CHOICE':
        if (!item.question.mandatory) {
          return;
        }
        if(!item.inputData || !item.inputData.length){
          invalids.push({
            index,
            item
          })
        }
        break;
      case 'SINGLE_CHOICE':
        if(!item.question.mandatory){
          return
        }
        if(!(item.inputData as SelectOption<Choice>).value){
          invalids.push({
            index,
            item
          })
        }
        break;
    }
  })
  return invalids;
}
