import React, { FC, FormEvent, useEffect, useState } from 'react';
import { GroupedAnswer, ProgressScope } from '../../interfaces/api.types';
import { FormFooter } from './form-footer';
import { SimpleQuestion } from '../questions/simple-question';
import { UpdateItemInArray } from '../../utils/deep-object.utils';
import { GroupGetInvalids, GroupSetAllValid, GroupSetInvalid } from './form.utils';
import { Checkbox } from '@linkeo.com/ui-lib-react';
import { PaddingForCard } from '../ui/padding-for-card';
import { FormattedMessage, useIntl } from 'react-intl';

interface ContactFormProps {
  context: ProgressScope;
  isLoading: boolean;
  onAnswer: (answer: ProgressScope) => void;
  onPrevious: () => void;
}

export const ContactForm: FC<ContactFormProps> = props => {
  const { context, onAnswer, onPrevious, isLoading } = props;
  const [getCurrentGroup, setCurrentGroup] = useState<GroupedAnswer[]>(context.groups || []);
  const [getAccept, setAccept] = useState<boolean>(false);
  const intl = useIntl();
  useEffect(() => {
    if (!context.groups) {
      return;
    }
    setCurrentGroup(context.groups);
  }, [context]);

  const checkValidity = (): boolean => {
    const invalids = GroupGetInvalids(getCurrentGroup);
    invalids.forEach(inv =>
      setCurrentGroup(GroupSetInvalid(getCurrentGroup, inv.item, inv.index)),
    );
    return !invalids.length;
  };

  const submit = (event: FormEvent) => {
    event.preventDefault();
    setCurrentGroup(GroupSetAllValid(getCurrentGroup));
    if (!checkValidity()) {
      return;
    }
    if (!getAccept) {
      return;
    }
    onAnswer({
      ...context,
      groups: getCurrentGroup,
    });
  };
  return <>
    <form onSubmit={submit}>
      <PaddingForCard>
        {getCurrentGroup.map((quest, qIndex) => <SimpleQuestion
          questionAnswer={quest}
          onChange={
            (qa) => setCurrentGroup(UpdateItemInArray(getCurrentGroup, qIndex, qa))
          }
          key={qIndex} />)}
        <Checkbox required value={getAccept} onChange={setAccept}>
          <FormattedMessage id={'rgpdNotice'} defaultMessage={'En soumettant ce formulaire,{br}j’accepte que les informations saisies soient exploitées{br}dans le cadre strict de ma demande'} values={{br: < br/>}} />
        </Checkbox>
      </PaddingForCard>
      <FormFooter title={intl.formatMessage({ id: 'seeMyEstimate', defaultMessage: 'Voir mon estimation' })} onCancel={onPrevious} isLoading={isLoading} />
    </form>
  </>;
};
