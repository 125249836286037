import React, { createContext, FC, ReactNode, RefObject, useContext, useRef, useState } from 'react';
import { Button, ContainerSize, Dialog, getTypo } from '@linkeo.com/ui-lib-react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

export interface DialogConfig {
  cancelBtnText?: string;
  confirmBtnText?: string;
  /**
   * content is recommended to be static, can be dynamic if not depending of cancel or confirm button
   */
  content: string | ReactNode,
  size?: ContainerSize
  title: string,
}

interface ConfirmContextData {
  openConfirm: (config: DialogConfig) => Promise<void>;
  rootRef: null | RefObject<HTMLDivElement>
}

const ConfirmContext = createContext<ConfirmContextData>({
  openConfirm: () => Promise.reject('not initialized'),
  rootRef: null
});
export const useConfirm = () => useContext(ConfirmContext);
const DefaultContainerDialog = styled.div`
  ${getTypo('body1')};
`;
export const ConfirmProvider: FC = props => {
  const { children } = props;
  const intl = useIntl();
  const rootRef = useRef<HTMLDivElement>(null);
  const [getDialog, setDialog] = useState<ReactNode | null>();

  const open = (config: DialogConfig): Promise<void> => {
    return new Promise((resolve, reject) => {
      const emitClose = (success?: boolean) => {
        setDialog(null);
        if (success) {
          resolve();
        } else {
          reject();
        }
      };
      const footer = <>
        <Button colorType={'secondary'}
                onClick={() => emitClose()}>{config.cancelBtnText || intl.formatMessage({ id: 'cancel', defaultMessage: 'Annuler' })}</Button>
        <Button colorType={'secondary'}
                onClick={() => emitClose(true)}>{config.confirmBtnText || intl.formatMessage({ id: 'ok', defaultMessage: 'Ok' })}</Button>
      </>;
      setDialog(
        <Dialog onClose={() => emitClose()} show={true} title={config.title} size={config.size || 'md'} footer={footer} rootRef={rootRef.current}>
          <DefaultContainerDialog>
            {config.content}
          </DefaultContainerDialog>
        </Dialog>,
      );
    });
  };

  return <ConfirmContext.Provider
    value={{ openConfirm: open, rootRef }}>
    {children}
    {getDialog ? getDialog : null}
  </ConfirmContext.Provider>;
};