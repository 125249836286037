import React, { FC } from 'react';
import { Button, Col, Row } from '@linkeo.com/ui-lib-react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

const Footer = styled.footer`
    padding: 0 max(5px, min(1vw, 48px)) 40px;
`;

interface FormFooterProps {
    title: string;
    onClick?: () => void;
    disabled?: boolean;
    onCancel?: () => void;
    isLoading?: boolean;
}

export const FormFooter: FC<FormFooterProps> = props => {
    const { title, onClick, disabled, onCancel, isLoading } = props;
    const intl = useIntl();
    return <Footer>
        <Row justifyContent={['center']}>
            {onCancel && <Col style={{marginBottom:'12px'}}>
                <Button
                  onClick={onCancel}
                  colorType={'outlineSecondary'}
                  style={{ textTransform: 'uppercase' }}>
                    {intl.formatMessage({ id: 'previous', defaultMessage: 'Précédent' })}
                </Button>
            </Col>}
            <Col>
                <Button
                    loading={isLoading}
                    disabled={disabled}
                    style={{textTransform: 'uppercase'}}
                    colorType={"secondary"}
                    type={onClick ? 'button' : 'submit'}
                    onClick={onClick ? onClick : undefined}

                >{title}</Button>
            </Col>
        </Row>

    </Footer>
}
