import React, { FC } from 'react';
import styled from 'styled-components';
import {LoadingContent,} from '@linkeo.com/ui-lib-react';

interface PageWrapperProps {
    isLoading: boolean;
}

const Main = styled.main`
  background: transparent;
  padding: 0 5px;
  position: relative;
`;

export const PageWrapper: FC<PageWrapperProps> = (props) => {

  const {isLoading, children} = props;

    return (
        <div>
            <LoadingContent isLoading={isLoading}>
                <Main id={'main'}>
                    {children}
                </Main>
            </LoadingContent>
        </div>
    );
};
