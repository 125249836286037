import React, { FC, useContext, useEffect, useState } from 'react';
import { SpanBody1, SpanSubtitle1, Svg, ThemeType } from '@linkeo.com/ui-lib-react';
import styled, { ThemeContext } from 'styled-components';
import { frontOfficeRootUri } from '../../config/config';
import { checkImageExists } from '../../utils/misc';
import { useIntl } from 'react-intl';

interface SelectableImageCardProps {
  active: boolean;
  onSelect: () => void;
  imgUrl?: string;
  name: string;
  description?: string
}

const Figure = styled.figure<{ active: boolean }>`
  padding: 0;
  margin: 0;
  cursor: pointer;
  outline: none;
  user-select: none;
  img {
    object-fit: cover;
    border-radius: 4px;
    margin-bottom: 5px;
    transition: border-color 200ms ease-in-out;
    border: 2px solid ${({active,theme}) => active ? theme.colors.primary : "#E5E5E5"};
  }

  &:hover img, &:focus img {
    border-color: ${({active,theme}) => active ? theme.colors.primary : "#aeadad"};
  }

  h6 > span {
    font-weight: ${({active}) => active ? "700" : "400"};
  }
`;
const FigCaption = styled.figcaption`
  text-align: center;
`;

export const SelectableImageCard: FC<SelectableImageCardProps> = props => {
  const {onSelect, imgUrl, description, active, name} = props;
  const theme = useContext<ThemeType>(ThemeContext);
  const intl = useIntl();
  const [imageExists, setImageExists] = useState<boolean>(false);

  useEffect(() => {
    if (imgUrl) {
      checkImageExists(imgUrl).then(exists => {
        setImageExists(exists)
      });
    }
    return () => setImageExists(false)
  }, [imgUrl]);

  const keyDownSelection = (event: React.KeyboardEvent) => {
    switch (event.code || event.keyCode) {
      case 'Space':
      case 32:
        event.preventDefault();
        onSelect();
        break;
    }
  }

  return <Figure active={active} tabIndex={0}
                 onClick={onSelect}
                 onKeyDown={keyDownSelection}>
    <div style={{width: "100%",maxWidth:"230px",height: "160px",position:'relative',margin: "0 auto"}}>
      <img
        draggable={false}
        alt={imageExists ? name : intl.formatMessage({ id: "image_unknown", defaultMessage: 'image non disponible' })}
        src={imageExists ? imgUrl : `${frontOfficeRootUri}/img/img-unknown.svg`}
        width={"100%"} height={"100%"} style={{objectFit:'cover'}}/>
      {active ? <Svg width={24} height={24}
                     icon={'check-circle'} fill={theme.colors.primary} style={{ position: 'absolute', top: 5 ,left:20}} /> : null}
    </div>
    <FigCaption>
      <h6 style={{margin: '5px 0 0 0'}}>
        <SpanBody1 style={{color: theme.colors.grey40}}>{name}</SpanBody1>
      </h6>
      <div>
        <SpanSubtitle1 style={{color: theme.colors.grey50}}>{description}</SpanSubtitle1>
      </div>
    </FigCaption>
  </Figure>
}
