import React, { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import styled, { useTheme } from 'styled-components';
import { Button, Card, getTypo, SpanBody1, SpanH5, SpanH6, Svg } from '@linkeo.com/ui-lib-react';
import { PaddingForCard } from '../ui/padding-for-card';

const Rect = styled.div`
  background: ${({ theme }) => theme.colors.grey95};
  border-radius: 4px;
  padding: 10px;
`;
const Text = styled.div`
  ${getTypo('body1')}
  color: ${({theme}) => theme.colors.grey20}
`

interface EstimateRecapCardProps {
  topSubtitle: string;
  result?: string;
  details?: string;
  children: ReactNode;
}

export const EstimateRecapCard: FC<EstimateRecapCardProps> = ({ topSubtitle, result, details, children }) => {
  const intl = useIntl();
  const theme = useTheme();

  const HTML_REGEX = /<\/?[a-z][\s\S]*>/i;
  const containsHTML = (str: string): boolean => HTML_REGEX.test(str);

  return <Card style={{ padding: '0' }}>
    <PaddingForCard as={'header'} style={{ textAlign: 'center' }}>
      <h2 style={{ margin: '0' }}>
        <SpanH6 style={{ textTransform: 'uppercase' }}>
          {intl.formatMessage({ id: 'resultTitle', defaultMessage: 'Votre estimation' })}
        </SpanH6>
      </h2>
    </PaddingForCard>
    <hr />
    <PaddingForCard as={'section'} style={{ textAlign: 'center' }}>
      <Svg height={48} width={48} fill={'black'}>
        <path fillRule='evenodd' clipRule='evenodd'
              d='M13 6H35C36.1046 6 37 6.89543 37 8V40C37 41.1046 36.1046 42 35 42H13C11.8954 42 11 41.1046 11 40V8C11 6.89543 11.8954 6 13 6ZM9 8C9 5.79086 10.7909 4 13 4H35C37.2091 4 39 5.79086 39 8V40C39 42.2091 37.2091 44 35 44H13C10.7909 44 9 42.2091 9 40V8ZM33 22V30H25L25 22H33ZM23 22H15L15 30H23L23 22ZM15 34V32H33V34H15ZM13 22V31V34C13 35.1046 13.8954 36 15 36H33C34.1046 36 35 35.1046 35 34V31V22C35 20.8954 34.1046 20 33 20H15C13.8954 20 13 20.8954 13 22ZM22 10H15V12H22V10ZM15 8C13.8954 8 13 8.89543 13 10V12C13 13.1046 13.8954 14 15 14H22C23.1046 14 24 13.1046 24 12V10C24 8.89543 23.1046 8 22 8H15ZM13 16V18H35V16H13ZM25 40H35V38H25V40ZM35 14H27V12H35V14Z'
              fill='#333333' />
      </Svg>
      <p>
        <SpanBody1 style={{ color: theme.colors.grey40 }}>
          {intl.formatMessage({
            id: 'resultSubtitle',
            defaultMessage: 'Merci d’avoir pris le temps de répondre à nos questions.',
          })}
          &nbsp;
          {topSubtitle}
        </SpanBody1>
      </p>
      {result && <Rect>
        <SpanH5>{result}</SpanH5>
      </Rect>
      }
      <div style={{ padding: '10px 0' }}>
        {details ?
          containsHTML(details) ? <Text dangerouslySetInnerHTML={{__html: details}} />
            : details.split('\n').map((msg, index) => (<div key={index}>
          <SpanBody1 style={{ color: theme.colors.grey20 }}>
            {msg}
          </SpanBody1>
        </div>)) : ''}
      </div>

      {children}

      <div style={{ marginTop: '30px', textAlign: 'center' }}>
        <Button onClick={() => window.location.href = ''} colorType={'secondary'}>
          {intl.formatMessage({ id: 'resultNewButton', defaultMessage: 'Demander une nouvelle estimation' })}
        </Button>
      </div>
    </PaddingForCard>
  </Card>;
};