import { ThemeColors, ThemeType } from '@linkeo.com/ui-lib-react';

const colors: ThemeColors = {
  primary: '#4CDC6C',
  invertPrimary: '#ffffff',
  secondary: '#1A1A1A',
  invertSecondary: '#ffffff',
  danger: '#ee4029',
  lightDanger: '#ff624c',
  success: '#34be01',
  lightSuccess: '#71ff5d',
  warning: '#bf8414',
  lightWarning: '#ffb531',
  light: '#ffffff',
  dark: '#000000',
  grey10: '#1a1a1a',
  grey20: '#333333',
  grey30: '#4d4d4d',
  grey40: '#666666',
  grey50: '#808080',
  grey60: '#999999',
  grey70: '#b3b3b3',
  grey80: '#cccccc',
  grey90: '#e6e6e6',
  grey95: '#f2f2f2',
};
export const Theme: ThemeType = {
  colors,
  input: {
    borderRadius: '4px',
    backgroundColor: colors.grey90,
    color: colors.grey20,
    borderColor: 'transparent',
    placeholderColor: colors.grey70,
  },
  box: {
    borderRadius: '4px',
  },
  icons: 'front',
  button: {
    small: {
      borderRadius: '20px',
      padding: '4px 12px 3px',
    },
    default: {
      borderRadius: '20px',
      padding: '11px 16px 9px',
    },
  },
  card: {
    padding: '40px max(10px, min(3vw, 40px))',
  },
  fonts: {
    input: {
      color: colors.grey10,
      family: '"Raleway", sans-serif',
      lineHeight: '1.5rem',
      size: '1.125rem',
      weight: '500',
      letterSpacing: 'initial',
      textTransform: 'initial',
      style: 'initial',
      textDecoration: 'initial',
      margin: '0',
    },
    h1: {
      color: colors.grey10,
      family: '"Open Sans", sans-serif',
      lineHeight: '2.25rem',
      size: '1.5rem',
      weight: 'initial',
      letterSpacing: 'initial',
      textTransform: 'initial',
      style: 'initial',
      textDecoration: 'initial',
      margin: '0 0 8px 0',
    },
    h2: {
      color: colors.grey10,
      family: '"Open Sans", sans-serif',
      lineHeight: '2.25rem',
      size: '1.875rem',
      weight: '800',
      letterSpacing: 'initial',
      textTransform: 'initial',
      style: 'initial',
      textDecoration: 'initial',
      margin: '0 0 8px 0',
    },
    h3: {
      color: colors.grey10,
      family: '"Open Sans", sans-serif',
      lineHeight: '1.75rem',
      size: '1.5rem',
      weight: '600',
      letterSpacing: 'initial',
      textTransform: 'initial',
      style: 'initial',
      textDecoration: 'initial',
      margin: '0 0 8px 0',
    },
    h4: {
      color: colors.grey10,
      family: '"Open Sans", sans-serif',
      lineHeight: '1.75rem',
      size: '1.25rem',
      weight: '800',
      letterSpacing: 'initial',
      textTransform: 'initial',
      style: 'initial',
      textDecoration: 'initial',
      margin: '0 0 8px 0',
    },
    h5: {
      color: colors.grey10,
      family: '"Open Sans", sans-serif',
      lineHeight: '36px',
      size: '24px',
      weight: '400',
      letterSpacing: 'initial',
      textTransform: 'initial',
      style: 'initial',
      textDecoration: 'initial',
      margin: '0 0 8px 0',
    },
    h6: {
      color: colors.grey10,
      family: '"Open Sans", sans-serif',
      lineHeight: '24px',
      size: '16px',
      weight: '700',
      letterSpacing: '5%',
      textTransform: 'initial',
      style: 'initial',
      textDecoration: 'initial',
      margin: '0 0 8px 0',
    },
    body1: {
      color: colors.grey10,
      family: '"Open Sans", sans-serif',
      lineHeight: '20px',
      size: '16px',
      weight: '400',
      letterSpacing: 'initial',
      textTransform: 'initial',
      style: 'initial',
      textDecoration: 'initial',
      margin: '0',
    },
    body2: {
      color: colors.grey10,
      family: '"Open Sans", sans-serif',
      lineHeight: '20px',
      size: '16px',
      weight: '700',
      letterSpacing: 'initial',
      textTransform: 'initial',
      style: 'initial',
      textDecoration: 'initial',
      margin: '0',
    },
    fontButton: {
      color: colors.grey10,
      family: '"Open Sans", sans-serif',
      lineHeight: '24px',
      size: '16px',
      weight: '700',
      letterSpacing: '0.2%',
      textTransform: 'initial',
      style: 'initial',
      textDecoration: 'initial',
      margin: '0',
    },
    smallFontButton: {
      color: colors.grey10,
      family: '"Open Sans", sans-serif',
      lineHeight: '1.125rem',
      size: '1rem',
      weight: '700',
      letterSpacing: 'initial',
      textTransform: 'initial',
      style: 'initial',
      textDecoration: 'initial',
      margin: '0',
    },
    subtitle1: {
      color: colors.grey20,
      family: '"Open Sans", sans-serif',
      lineHeight: '18px',
      size: '14px',
      weight: '400',
      letterSpacing: 'initial',
      textTransform: 'initial',
      style: 'initial',
      textDecoration: 'initial',
      margin: '0',
    },
    subtitle2: {
      color: colors.grey20,
      family: '"Open Sans", sans-serif',
      lineHeight: '1.125rem',
      size: '1rem',
      weight: '700',
      letterSpacing: 'initial',
      textTransform: 'initial',
      style: 'initial',
      textDecoration: 'initial',
      margin: '0',
    },
    caption1: {
      color: colors.grey30,
      family: '"Open Sans", sans-serif',
      lineHeight: '16px',
      size: '13px',
      weight: '400',
      letterSpacing: 'initial',
      textTransform: 'initial',
      style: 'italic',
      textDecoration: 'initial',
      margin: '0',
    },
    caption2: {
      color: colors.grey30,
      family: '"Open Sans", sans-serif',
      lineHeight: '16px',
      size: '13px',
      weight: '700',
      letterSpacing: 'initial',
      textTransform: 'initial',
      style: 'italic',
      textDecoration: 'initial',
      margin: '0',
    },
    link: {
      color: colors.grey10,
      family: '"Open Sans", sans-serif',
      lineHeight: '1.5rem',
      size: '1.125rem',
      weight: '600',
      letterSpacing: 'initial',
      textTransform: 'initial',
      style: 'initial',
      textDecoration: 'underline',
      margin: '0',
    },
  },
};
