import axios, {AxiosError, AxiosRequestConfig} from 'axios';
import React, {createContext, FC, useContext} from 'react';
import {ApiAnswer, ApiEstimation, ApiForm} from '../interfaces/api.types';
import { useIntl } from 'react-intl';

type ObjectStringKey = {
    [key: string]: any
}

interface RequestOptions {
    headers?: any,
    params?: any,
    withoutCodeBouton?: boolean,
    noRedirectAfterError?: boolean
}

export class ApiRequest {
    constructor(private codeBouton?: string, private api?: string, private locale?: string) {
    }

    async getForm(formId: string): Promise<ApiForm> {
        return this.sendRequest('get', `/estimation/${formId}/form`);
    }

    async postAnswers(formId:string,answers: { questions: ApiAnswer[], activities: string[] }): Promise<ApiEstimation> {
        return this.sendBodyRequest('post', `/estimation/${formId}/answers`, answers);
    }

    private async sendBodyRequest<T>(type: 'post' | 'put' | 'patch', route: string, body = {}, options: RequestOptions = {}) {
        const config = await this.mergeConfig(options.params, options.headers);
        const code = options.withoutCodeBouton ? '' : this.codeBouton;
        let result;
        switch (type) {
            case 'post':
            default:
                result = await axios.post<T>(`${this.api}/${code}${route}`, body, config).catch((error) => this.axiosErrorTreatment(error));
                break;
            case 'put':
                result = await axios.put<T>(`${this.api}/${code}${route}`, body, config).catch((error) => this.axiosErrorTreatment(error));
                break;
            case 'patch':
                result = await axios.patch<T>(`${this.api}/${code}${route}`, body, config).catch((error) => this.axiosErrorTreatment(error));
                break;
        }
        return result ? result.data : Promise.reject();
    }

    private async sendRequest<T>(type: 'get' | 'delete', route: string, options: RequestOptions = {}): Promise<T> {
        const config = await this.mergeConfig(options.params, options.headers);
        const code = options.withoutCodeBouton ? '' : this.codeBouton;
        let result;
        switch (type) {
            case 'get':
            default:
                result = await axios.get<T>(`${this.api}/${code}${route}`, config).catch((error) => this.axiosErrorTreatment(error));
                break;
            case 'delete':
                result = await axios.delete<T>(`${this.api}/${code}${route}`, config).catch((error) => this.axiosErrorTreatment(error));
                break;
        }
        return result ? result.data : Promise.reject();
    }

    private async mergeConfig(params: ObjectStringKey = {}, headers: ObjectStringKey = {}): Promise<AxiosRequestConfig> {
        for (const paramsKey in params) {
            if (Object.prototype.hasOwnProperty.call(params, paramsKey)) {
                if (params[paramsKey] === '') {
                    delete params[paramsKey];
                }
                if (typeof params[paramsKey] === 'undefined') {
                    delete params[paramsKey];
                }
                if (params[paramsKey] === null) {
                    delete params[paramsKey];
                }
            }
        }

        return {
            params,
            headers: {
                "Accept-Language" : this.locale || "fr-FR",
                ...headers,
            },
        };
    }

    private async axiosErrorTreatment(error: AxiosError) {
        throw error;
    }
}

const ApiContext = createContext<ApiRequest>(new ApiRequest());
export const useApi = () => useContext(ApiContext);
export const ApiProvider: FC<{api: string, codeBouton: string}> = props => {
    const {children, api, codeBouton} = props;
    const {locale} = useIntl();
    return <ApiContext.Provider
        value={new ApiRequest(codeBouton, api, locale)}>
        {children}
    </ApiContext.Provider>;
};
