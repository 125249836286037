import React, { FC, useContext } from 'react';
import { Flex, getTypo, Svg, ThemeType } from '@linkeo.com/ui-lib-react';
import styled, { ThemeContext } from 'styled-components';
import { FormattedMessage } from 'react-intl';

const Background = styled.div`
  height: 100vh;
  position: relative;
  background: #f5f5f5;
`;
const H1 = styled.h1`
  ${getTypo('h1')};
  color: black;
  margin: 10px 0;
`;
const Text = styled.p`
  ${getTypo('h6')};
  color: ${({ theme }) => theme.colors.grey40};
  margin: 30px 0;
`;

export const Error404: FC<{missingArg?: boolean}> = ({missingArg}) => {
  const theme = useContext<ThemeType>(ThemeContext);
  return (
    <Background>
      <Flex justifyContent={['center']} style={{ height: '100%' }}>
        <div style={{ textAlign: 'center' }}>
          <Svg icon={'cross-circle'} width={36} height={36} fill={theme.colors.grey60} />
          <H1>
            <FormattedMessage id={'404ErrorTitle'} defaultMessage={'Erreur 404'} />
          </H1>
          <Text>
            <FormattedMessage id={'404ErrorMessage'}
                              defaultMessage={'La page recherchée est introuvable'} />
          </Text>
          {missingArg && <Text>
            <FormattedMessage id={'404ErrorExplanation'}
                              defaultMessage={'Il manque un argument (codebouton et/ou formid) dans la configuration.'} />
          </Text>}
        </div>
      </Flex>
    </Background>
  );
};