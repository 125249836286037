import React, { FC, useContext } from 'react';
import { ApiEstimation, EXACT, FROM, MANUAL, price, surface, volume } from '../../interfaces/api.types';
import { useIntl } from 'react-intl';
import { EstimateRecapCard } from './estimate-recap-card';
import { getTypo, SpanBody1, ThemeType } from '@linkeo.com/ui-lib-react';
import styled, { ThemeContext } from 'styled-components';
import { AIDES_ET_PRIMES } from '../../interfaces/category.types';

const Text = styled.div`
  ${getTypo('body1')};
  ${({ theme }) => theme.colors.grey40}
`;

interface EstimationPresentationProps {
  result: ApiEstimation;
  eligibilityMessages?: string[];
  type?: typeof AIDES_ET_PRIMES | null;
}

export const EstimationPresentation: FC<EstimationPresentationProps> = ({
                                                                          result,
                                                                          eligibilityMessages,
                                                                          type,
                                                                        }) => {
  const intl = useIntl();
  const theme = useContext<ThemeType>(ThemeContext);

  const getAmount = (): string => {
    const tvaText = result.tax === 'TVA' ? intl.formatMessage({
      id: 'withTax',
      defaultMessage: '(TTC)',
    }) : intl.formatMessage({
      id: 'withoutTax', defaultMessage:
        '(HT)',
    });
    if (!result.price || !result.unit) {
      return '';
    }
    if (result.display === EXACT) {
      return intl.formatMessage({ id: 'displayPriceExact', defaultMessage: '{amount} {tax} {unit}' }, {
        amount: intl.formatNumber(result.price.min, {
          style: result.unit.type === price ? 'currency' : 'decimal',
          currency: result.currency,
        }),
        tax: result.unit.type === price && type !== AIDES_ET_PRIMES ? tvaText : '',
        unit: result.unit.valueCurrency ?? '',
      });
    }
    if (result.display === FROM || result.price.min === result.price.max) {
      return intl.formatMessage({ id: 'displayPriceFrom', defaultMessage: 'À partir de {amount} {tax} {unit}' }, {
        amount: intl.formatNumber(result.price.min, {
          style: result.unit.type === price ? 'currency' : 'decimal',
          currency: result.currency,
        }),
        tax: result.unit.type === price && type !== AIDES_ET_PRIMES ? tvaText : '',
        unit: result.unit.valueCurrency ?? '',
      });
    } else {
      return intl.formatMessage({ id: 'displayPriceBetween', defaultMessage: 'Entre {min} et {max} {tax} {unit}' }, {
        max: intl.formatNumber(result.price.max, {
          style: result.unit.type === price ? 'currency' : 'decimal',
          currency: result.currency,
        }),
        min: intl.formatNumber(result.price.min, {
          style: result.unit.type === price ? 'currency' : 'decimal',
          currency: result.currency,
        }),
        tax: result.unit.type === price && type !== AIDES_ET_PRIMES ? tvaText : '',
        unit: result.unit.valueCurrency ?? '',
      });
    }
  };

  return <EstimateRecapCard
    result={result.display !== MANUAL ? type === AIDES_ET_PRIMES
        ? intl.formatMessage({
          id: 'eligibleMessage',
          defaultMessage: 'ÉLIGIBLE',
        }) + (result.price ? ' : ' + getAmount() : '')
        : getAmount()
      : type === AIDES_ET_PRIMES
        ? intl.formatMessage({
          id: 'eligibleMessage',
          defaultMessage: 'ÉLIGIBLE',
        }) : undefined}
    details={result.details}
    topSubtitle={result.display !== MANUAL
      ? result.unit.type === price ? intl.formatMessage({
        id: 'resultSubtitlePrice', defaultMessage:
          'Nous estimons votre tarif :',
      }) : intl.formatMessage({
        id: 'resultSubtitleValueCurrency',
        defaultMessage: 'Estimation en {value}',
      }, {
        value: result.unit.value === surface ? intl.formatMessage({ id: 'surface', defaultMessage: 'surface' })
          : result.unit.value === volume ? intl.formatMessage({ id: 'volume', defaultMessage: 'volume' })
            : result.unit.value || '',
      })
      : ''}>
    <>
      {eligibilityMessages && eligibilityMessages.map((msg, i) =>
        <Text key={i} dangerouslySetInnerHTML={{ __html: msg }} />)}
      <p>
        <SpanBody1 style={{ color: theme.colors.grey40 }}>
          {result.display !== MANUAL ? intl.formatMessage({
            id: 'resultContact1', defaultMessage:
              'Vous allez recevoir cette estimation par Email et nous reviendrons vers vous pour établir un devis plus précis.',
          }) : intl.formatMessage({
            id: 'resultContact1Manual',
            defaultMessage: 'Vous allez recevoir le récapitulatif de votre formulaire par email. Nous reviendrons vers vous pour établir un devis.',
          })}
        </SpanBody1>
      </p>
      <p>
        <SpanBody1 style={{ color: theme.colors.grey40 }}>
          {intl.formatMessage({
            id: 'resultContact2', defaultMessage:
              'Si vous avez d’autres questions/besoins, n’hésitez pas à nous contacter.',
          })}
        </SpanBody1>
      </p>
      <p>
        <SpanBody1 style={{ color: theme.colors.grey40 }}>
          {intl.formatMessage({ id: 'bye', defaultMessage: 'À Bientôt' })}
        </SpanBody1>
      </p>
    </>
  </EstimateRecapCard>;
};
