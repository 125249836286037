import React, { FC, FormEvent, useEffect, useState } from 'react';
import { GroupedAnswer, ProgressScope } from '../../interfaces/api.types';
import { InOut } from '@linkeo.com/ui-lib-react';
import { SimpleQuestion } from '../questions/simple-question';
import { FormFooter } from './form-footer';
import { UpdateItemInArray } from '../../utils/deep-object.utils';
import { GroupGetInvalids, GroupSetAllValid, GroupSetInvalid } from './form.utils';
import { PaddingForCard } from '../ui/padding-for-card';
import { useIntl } from 'react-intl';

interface TargetingFormProps {
    context: ProgressScope;
    onAnswer: (answer: ProgressScope) => void;
}

export const TargetingForm: FC<TargetingFormProps> = props => {
    const { context, onAnswer } = props;
    const intl = useIntl()
    const [getCurrentGroup, setCurrentGroup] = useState<GroupedAnswer[]>(context.groups || []);
    useEffect(() => {
        if (!context.groups) {
            return;
        }
        setCurrentGroup(context.groups)
    }, [context])

    const checkValidity = (): boolean=>{
        const invalids = GroupGetInvalids(getCurrentGroup);
        invalids.forEach(inv=>
          setCurrentGroup(GroupSetInvalid(getCurrentGroup,inv.item,inv.index))
        );
        return !invalids.length;
    }

    const submit = (event: FormEvent) => {
        event.preventDefault();
        setCurrentGroup(GroupSetAllValid(getCurrentGroup))
        if(!checkValidity()){
            return;
        }
        onAnswer({
            ...context,
            groups: getCurrentGroup
        })
    }

    return <InOut show={true} startTriggering>
        <form onSubmit={submit}>
            <PaddingForCard as={"section"}>
                {getCurrentGroup.map((quest, qIndex) => <SimpleQuestion
                  questionAnswer={quest}
                  onChange={
                      (qa) => setCurrentGroup(UpdateItemInArray(getCurrentGroup, qIndex, qa))
                  }
                  key={qIndex} />)}
            </PaddingForCard>
            <FormFooter title={intl.formatMessage({ id: 'validate', defaultMessage: 'Valider' })} />
        </form>
    </InOut>
}
