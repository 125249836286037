import React, { FC } from 'react';
import { ChoiceSelection, GroupedAnswer } from '../../interfaces/api.types';
import {
  Checkbox,
  Col, getTypo,
  InOut,
  Input,
  InputNumber,
  LabelWrapper,
  Radio,
  Row,
  SpanSubtitle1,
  Switch,
  Textarea,
} from '@linkeo.com/ui-lib-react';
import { QuestionChoices, QuestionNumber } from '../../interfaces/question.types';
import { SelectableImageCard } from '../ui/selectable-image-card';
import { Choice } from '../../interfaces/choice.types';
import { FindAndUpdateItemInArray, FindByAndRemoveAllItemInArray } from '../../utils/deep-object.utils';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

const Html = styled.div`
  ${getTypo('subtitle1')};
  > p {
    margin: 0
  }
`;

interface ActivityQuestionProps {
  questionAnswer: GroupedAnswer;
  onChange: (val: GroupedAnswer) => void;
}

export const ActivityQuestion: FC<ActivityQuestionProps> = props => {
  const { questionAnswer, onChange } = props;
  const { question, inputData, isInvalid, booleanFirst } = questionAnswer;
  const intl = useIntl();

  const HTML_REGEX = /<\/?[a-z][\s\S]*>/i;
  const containsHTML = (str: string): boolean => HTML_REGEX.test(str);

  const hasImage = (): boolean => {
    if (question.type === 'SINGLE_CHOICE' || question.type === 'MULTIPLE_CHOICE') {
      return (question as QuestionChoices).choices.some(c => !!c.picture);
    }
    return false;
  };

  const renderQuantityChoice = (c: Choice) => {
    return c.addChoiceQuantity ?
      <InputNumber
        label={c.choiceQuantityLabel + (c.choiceQuantityUnit ? ` (${c.choiceQuantityUnit})` : '')}
        required={isSelected(c)}
        min={isSelected(c) ? 1 : undefined}
        value={getChoiceValue(c) || 0}
        step='any'
        onChange={(v) => question.type === 'SINGLE_CHOICE' ? selectedOne(c, v || 0) : selectedMultiple(c, v || 0)}
      /> : null;
  };

  const renderToggleFirst = () => {
    return (question as QuestionChoices).booleanFirst ? <Row
      justifyContent={['center']}>
      <Col><Switch
        onLabel={intl.formatMessage({ id: 'yes', defaultMessage: 'Oui' })}
        offLabel={intl.formatMessage({ id: 'no', defaultMessage: 'Non' })}
        value={!!booleanFirst}
        onChange={(val) => onChange({
            ...questionAnswer,
            booleanFirst: val,
            responsesTexte: val ? [] : [intl.formatMessage({ id: 'no', defaultMessage: 'Non' })],
          },
        )} /></Col></Row> : null;
  };

  const isSelected = (choice: Choice): boolean => {
    if (!inputData) {
      return false;
    }
    return (inputData as ChoiceSelection[]).some(c => c.id === choice.id);
  };

  const getChoiceValue = (choice: Choice): number | null => {
    if (!inputData) {
      return null;
    }
    const find = (inputData as ChoiceSelection[]).find(c => c.id === choice.id);
    if (!find) {
      return null;
    }
    return find.quantity || 0;
  };

  const selectedOne = (choice: Choice, quantity?: number) => {
    onChange({
      ...questionAnswer,
      inputData: [{
        id: choice.id,
        quantity,
        choice,
      } as ChoiceSelection],
    });
  };

  const selectedMultiple = (choice: Choice, quantity?: number) => {
    let data: ChoiceSelection[] = [];
    if (inputData) {
      data = inputData as ChoiceSelection[];
    }
    const find = data.find(cs => cs.id === choice.id);
    if (!find) {
      data.push({
        id: choice.id,
        quantity,
        choice,
      } as ChoiceSelection);
    } else {
      if (typeof quantity !== 'undefined') {
        data = FindAndUpdateItemInArray(data, 'id', {
          id: choice.id,
          quantity,
          choice,
        } as ChoiceSelection);
      } else {
        data = FindByAndRemoveAllItemInArray(data, 'id', choice.id);
      }
    }
    onChange({
      ...questionAnswer,
      inputData: data,
    });
  };

  const renderChoices = () => {
    return <InOut show={true} startTriggering><Row wraps={['wrap']}>
      {(question as QuestionChoices).choices.map((c, cIndex) =>
        <Col columns={[12, 6, 4, 4]} key={cIndex} style={{ marginBottom: '20px' }}>
          <SelectableImageCard
            imgUrl={c.picture?.absoluteUrl}
            active={isSelected(c)}
            onSelect={() => question.type === 'SINGLE_CHOICE' ? selectedOne(c) : selectedMultiple(c)}
            name={c.label}
            description={c.description || ''} />
          {renderQuantityChoice(c)}
        </Col>)}
    </Row>
    </InOut>;
  };

  switch (question.type) {
    default:
    case 'SHORT_TEXT':
      return <>
        {question.description && containsHTML(question.description) && <label htmlFor={'long_text_q'}>
          <Html dangerouslySetInnerHTML={{__html: question.description}}></Html>
        </label>}
        <Input
        label={question.description && !containsHTML(question.description) && question.description }
        required={question.mandatory}
        hasError={isInvalid}
        value={inputData}
        onChange={val => onChange({
          ...questionAnswer,
          inputData: val,
          responsesTexte: [val],
        })}
      /></>;
    case 'QUANTITY':
      return <>
        {question.description && containsHTML(question.description) && <label htmlFor={'long_text_q'}>
          <Html dangerouslySetInnerHTML={{__html: question.description + ((question as QuestionNumber).unit ? ` (${(question as QuestionNumber).unit})` : '')}}></Html>
        </label>}
        <InputNumber
        label={question.description && !containsHTML(question.description) && question.description + ((question as QuestionNumber).unit ? ` (${(question as QuestionNumber).unit})` : '')}
        hasError={isInvalid}
        required={question.mandatory}
        value={(inputData as number)}
        step='any'
        min={question.mandatory ? 1 : undefined}
        onChange={(v) => onChange({
          ...questionAnswer,
          inputData: v,
          responsesTexte: [(v || 0).toString(10) + ((question as QuestionNumber).unit ? ` ${(question as QuestionNumber).unit}` : '')],
        })}
      /></>;
    case 'LONG_TEXT':
      return <>
        {question.description && containsHTML(question.description) && <label htmlFor={'long_text_q'}>
          <Html dangerouslySetInnerHTML={{__html: question.description}}></Html>
        </label>}
        <Textarea
          id={'long_text_q'}
          label={question.description && !containsHTML(question.description) && question.description}
          value={(inputData as string)}
          hasError={isInvalid}
          required={question.mandatory}
          onChange={(v) => onChange({
            ...questionAnswer,
            inputData: v,
            responsesTexte: [v],
          })}
        /></>;
    case 'SINGLE_CHOICE':
      return <>
        {renderToggleFirst()}
        {question.description && containsHTML(question.description)
          ? <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            <Html dangerouslySetInnerHTML={{ __html: question.description }}></Html>
          </div>
          : question.description ? <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            <SpanSubtitle1>{question.description}</SpanSubtitle1></div> : null}
        {
          booleanFirst ? hasImage() ? renderChoices() : <Row wraps={['wrap']}>
              {
                (question as QuestionChoices).choices.map(c =>
                  <Col columns={[12, 6]} key={c.id} style={{ marginBottom: '20px' }}>
                    <LabelWrapper caption={c.description || ''}>
                      <Radio
                        name={question.id}
                        value={isSelected(c)}
                        onChange={() => selectedOne(c)}>{
                        c.label
                      }</Radio>
                    </LabelWrapper>
                    {renderQuantityChoice(c)}
                  </Col>
                )
              }
            </Row>
            : null
        }
      </>
    case 'MULTIPLE_CHOICE':
      return <>
        {renderToggleFirst()}
        {question.description && containsHTML(question.description)
          ? <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            <Html dangerouslySetInnerHTML={{ __html: question.description }}></Html>
          </div>
          : question.description ? <div style={{ textAlign: 'center', marginBottom: '20px' }}>
            <SpanSubtitle1>{question.description}</SpanSubtitle1></div> : null}
        {
          booleanFirst ? hasImage() ? renderChoices() : <Row wraps={['wrap']}>
            {
              (question as QuestionChoices).choices.map(c =>
                <Col columns={[12, 6]} key={c.id} style={{ marginBottom: '20px' }}>
                  <LabelWrapper caption={c.description || ''}>
                    <Checkbox
                      value={isSelected(c)}
                      onChange={() => selectedMultiple(c)}>
                      {c.label}
                    </Checkbox>
                  </LabelWrapper>
                  {renderQuantityChoice(c)}
                </Col>,
              )
            }
          </Row> : null
        }
      </>;
  }
};
